import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ActionForm from "../components/action-form"
import MoreBlogPosts from "../components/more-blog-posts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons"

export default function BlogPostTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { tags } = frontmatter

  // transform tags to paths
  let tagList = []
  tags.forEach(tag => {
    let path = tag.toLowerCase().replace(/ /g, "-")
    tagList.push({
      name: tag,
      path: `/tags/${path}/`,
    })
  })

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.teaser} />
      <section className="container pt-8 pt-md-11">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8">
            <h1 className="display-4 text-center font-weight-bold">{frontmatter.title}</h1>
            <p className="lead mb-7 text-center text-muted">
              {frontmatter.teaser}
            </p>
          </div>
        </div>

        <header>
          <hr />
          <p className="text-muted text-center">
            {frontmatter.date}
            <Link
              to="/contact/"
              className="btn btn-sm btn-outline-secondary event-contact ml-3"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />
              Ask a question{" "}
            </Link>
          </p>
          <hr />
        </header>

        <main
          id="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>

      <ActionForm source={frontmatter.title} />

      <section className="container my-5 text-center">
        {tagList.map((tag, index) => {
          return (
            <Link
              to={tag.path}
              role="button"
              className="btn btn-pill btn-sm btn-light mr-2 mb-2 text-capitalize"
              key={index}
            >
              {tag.name}
            </Link>
          )
        })}
      </section>

      <section className="container my-5 text-center">
        <a
          href="https://app.celantur.com/"
          className="btn btn-success lift event-start-demo"
        >
          Start Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </a>
        <Link
          to="/contact/"
          className="btn btn-outline-secondary ml-3 lift event-contact"
        >
          Contact Us
        </Link>
      </section>
      <MoreBlogPosts />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        path
        title
        author
        teaser
        email
        tags
      }
    }
  }
`
